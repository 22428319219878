import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import Vid from "../../components/video";
import Accordion from 'react-bootstrap/Accordion';

const SwiftSong = () => {

    const info={
        video:false,
        title:'The Swift Song',
        link:'https://www.youtube.com/embed/onWAZcFlCsI',
        description:"The Swift Song is the theme song of the mission; an upbeat, catchy pop tune which teaches listeners about the math and science behind the elusive gamma ray bursts and the Swift satellite. It's a roller coaster gamma ray ride!",
        internalText:"The Swift Song is the theme song of the mission; an upbeat, catchy pop tune which teaches listeners about the math and science behind the elusive gamma ray bursts and the Swift satellite. It's a roller coaster gamma ray ride! Performed by The Chromatics as part of their AstroCappella educational project: www.astrocappella.com. The Chromatics are an a capella group of astrophysicists including Padi Boyd, Alan Smale, Karen Smale, Lisa Kelleher (all from NASA/Goddard Space Flight Center), John Meyer (JHU), Deb Nixon, and Paul Kolb. Lyrics and music written by Padi Boyd. Produced by Jeff Gruber (Blue House Productions) and The Chromatics. Copyright 1999 P. Boyd.",
        //the transcript here is just blatantly incorrect
        transcript:[
          'We know that gamma ray explosions happen randomly all over the sky',
          "(It's like a lottery: a ticket for each square degree)",
          "You see a FLASH! and then there's not another till about a day has gone by",
          "(But that depends upon detector sensitivity)",
          "In just a moment they spew energy worth (That's pretty fast)",
          "A value we can't even fathom on Earth (It's really vast!)",
          "But just what's giving rise to gamma ray sparked skies?",
          "Is it the death cry of a massive star or black hole birth?",
          "(Or both, or both? or both!)",
          
          "Chorus:",
          "Swiftly swirling, gravity twirling",
          "Neutron stars about to collide",
          "Off in a galaxy so far away",
          "Catastrophic interplay",
          "A roller coaster gamma ray ride",
          "Superbright explosion then",
          "Never to repeat again",
          "How are we supposed to know?",
          "How about a telescope rotation",
          "Swiftly onto the location",
          "Of its panchromatic afterglow?",
  
          "In just a moment gamma ray bursts reach a peak and swiftly fade from view",
          "(It's like a beacon shining clear across the Universe)",
          "But they leave embers in the longer wavelengths fading for a day or two",
          "(It's exponential -- it decays forever)",
          "To solve this space age mystery is why (We wanna know)",
          "We want to catch a thousand bursts on the fly (What makes' em go?)",
          "Their X-ray light disperse unlock the Universe",
          "Measure their distance from their redshift mark their spot on the sky",
          "(They're where? They're here! They're there! They're everywhere!)",
  
          "(Chorus)",
  
          "Swiftly swirling, gravity twirling",
          "Neutron stars about to collide",
          "Off in a galaxy so far away",
          "Catastrophic interplay",
          "A roller coaster gamma ray ride",
          "Superbright explosion then",
          "Never to repeat again",
          "How are we supposed to know?",
          "Swift is the satellite that swings",
          "Onto those brightly bursting things",
          "To grab the multiwavelength answer to what makes them glow",
  
          "It's like a lottery - a ticket for each square degree",
          "It's like a beacon shining clear across the Universe",
          "Swift is the satellite that swiftly swings all over the sky",
          "Swift is designed to catch a burst of gamma rays on the fly",
        ]
      }

  return (
    <Layout>
        <div style={{width:'100%'}}>
            <h1>{info.title}</h1>
            <Star />
            <Vid videoLink={info.link}/>
            <Accordion flush>
                <Accordion.Item eventKey={1} key={1}>
                        <Accordion.Header>Transcript</Accordion.Header>
                        <Accordion.Body>
                                {info.transcript ? 
                                    info.transcript.map( p => <p style={{textAlign:'start'}}>{p}</p>)
                                    : 
                                    null
                                }
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p style={{textAlign:'start', margin:'1em 0'}}>{info.internalText}</p>
        </div>
    </Layout>
  )
}

export default SwiftSong